import React from "react";
import '../App.css'

function Vision(){

    return(
        <>

<div class="container my-5">
        <h2 class="text-center mb-4 success-title">Success Stories</h2>
        <div class="row text-center">
           
            <div class="col-md-4 col-sm-6 col-12 mb-3 success-item">
                <div class="story-box p-3">
                    <h5 class="story-title">Galleria Designs (Pvt) Limited</h5>
                </div>
            </div>
           
            <div class="col-md-4 col-sm-6 col-12 mb-3 success-item">
                <div class="story-box p-3">
                    <h5 class="story-title">Papa John's Pakistan</h5>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 mb-3 success-item">
                <div class="story-box p-3">
                    <h5 class="story-title">Kruncheese Pakistan</h5>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 mb-3 success-item">
                <div class="story-box p-3">
                    <h5 class="story-title">Sukh Chain Housing Society</h5>
                    <p class="story-subtitle">Icon Constructions</p>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 mb-3 success-item">
                <div class="story-box p-3">
                    <h5 class="story-title">Yuksel Seeds Asia (Pvt) Ltd</h5>
                    <p class="story-subtitle">Mian Shadi Agriculture</p>
                </div>
            </div>
        </div>
    </div>



{/* 
<section id="Contact mt-5">
      <h1 className="High text-center portfolioheading">Contact</h1>
      <section className="contact-section">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="contact-info">
                <h2>Contact Us</h2>
                <p className="mt-4">We'd love to hear from you! Fill out the form below, or reach us through social media.</p>
                <div className="contact-details">
                  <div className="contact-item">
                    <i className="fa fa-phone"></i>
                    <p>+92 312 4946583</p>
                  </div>
                  <div className="contact-item">
                    <i className="fa fa-envelope"></i>
                    <p>Technologent Solutions</p>
                  </div>
                  <div className="contact-item">
                    <i className="fa fa-map-marker"></i>
                 
                  </div>
                </div>
                <div className="social-media">
                  <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon"><i className="fa fa-facebook"></i></a>
                  <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon"><i className="fa fa-twitter"></i></a>
                  <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon"><i className="fa fa-instagram"></i></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="contact-form">
                <h2 className="mt-2">Send Us a Message</h2>
                <form action="#" method="post">
                  <div className="form-group">
                    <label htmlFor="name"></label>
                    <input type="text" placeholder="Your Name" id="name" name="name" required className="form-control" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email"></label>
                    <input type="email" placeholder="Your Email" id="email" name="email" required className="form-control" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message"></label>
                    <textarea id="message" placeholder="How can we help?" name="message" rows="5" required className="form-control"></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary portfolioheadingt mt-4">Send Message</button>
                </form>
              </div>
            </div>
          </div>
         
        </div>
      </section>
    </section> */}












        
       {/* <div className="container-fluid mainstatus">
       <div className="container-fluid mainstatus">
       <div className="row">
       <div className="col-md-6 visioncontainer">
 <img className="img-fluid mt-3 px-4 py-4 missionpass" src="/images/businessmission.png"/>
       </div>
       <div className="col-md-6 mission visioncontainer">
         <div className="mt-3">
            <h1>OUR VISION</h1>
            <p classname="missions_container">Our Mission is “Offering manag-ement and financial services to corporations, guiding policy
            formulation to attain strategic financial and sustainable goals, all within the constraints of
             budgetary limitations. Our commitment extends beyond numbers preparation to strategic
             development that brings those numbers to fruition.”</p>
         </div>
         <div className="mt-3">
            <h1>Our Mission</h1>
            <p classname="missions_container">Our Mission is “Offering manag-ement and financial services to corporations, guiding policy
            formulation to attain strategic financial and sustainable goals, all within the constraints of
             budgetary limitations. Our commitment extends beyond numbers preparation to strategic
             development that brings those numbers to fruition.”</p>
         </div>
         <div className="mt-3">
            <h1>OUR STRATEGY</h1>
            <p classname="missions_container">Our Mission is “Offering manag-ement and financial services to corporations, guiding policy
            formulation to attain strategic financial and sustainable goals, all within the constraints of
             budgetary limitations. Our commitment extends beyond numbers preparation to strategic
             development that brings those numbers to fruition.”</p>
         </div>
       </div>

       </div>

       </div>

       </div>


 */}



   


       <div className="container-fluid mt-5 ">
    <hr />
    <div className="row undervision">
        <div className="col-md-2 offset-md-1 text-center">
            <img className="diagrams img-fluid" src="/images/strategy.png" alt="Strategy"/>
            <h2 className="mt-3 ourwork">STRATEGY</h2>
        </div>
        <div className="col-md-3 text-center">
            <img className="diagrams img-fluid" src="/images/planning.png" alt="Planning"/>
            <h2 className="mt-3 ourwork">PLANNING</h2>
        </div>
        <div className="col-md-3 text-center">
            <img className="diagrams img-fluid" src="/images/build.png" alt="Build"/>
            <h2 className="mt-3 ourwork">BUILD</h2>
        </div>
        <div className="col-md-3 text-center">
            <img className="img-fluid diagrams" src="/images/ourwork.png " alt="Our Work"/>
            <h2 className="mt-3 ourwork">OUR WORK</h2>
        </div>
    </div>
    <hr className="hr"/>
</div>


<div className="container-fluid nextone">
      <h1  className="onestop text-center">we are one-stop solution proviiders</h1>
    </div>

<div className="container secondone">
    <img className="img-fluid" src="/images/provider.png"/>
</div>
        </>
    )
}

export default Vision;