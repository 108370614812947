import React from 'react'
import Landingpage from './Landingpage';

import Vision from './Vision';
import Footer from './Footer';
import '../App.css';

const  Home = () =>{

return(
<>

<Landingpage/>
{/* <Firstcard/> */}
<Vision/>
<Footer/> 
    
</>

)

}

export default Home;