import React from "react";
import '../App.css';
import { Link, useEffect } from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
import Service from "./Service";

function Footer() {
    return (
        <>
            <div className="container-fluid footercontainer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div>
                                <a href="#"> <img className="albarooj img-fluid" src="/images/logo.png" alt="Logo" /> </a>
                                <p>At Technologent solutions, whether you’re an SME or a large-scale business, expect unparalleled attention and service that surpasses your expectations.</p>
                                <div>
                                    <a href="#">Twitter</a>
                                    <a href="#">Facebook</a>
                                    <a href="#">LinkedIn</a>
                                    <a href="#">Instagram</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <div>
                                <h1>Company</h1>
                                <ul className="list-unstyled">
                                    <li><Link to="/about">About US</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="/team">Team</Link></li>
                                    <li><Link to="/Service">Services</Link></li>
                                    {/* <li><a href="#">FAQS</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div>
                                <h1>Solutions</h1>
                                <ul className="list-unstyled">
                                    <li><a href="#">Oracle APEX</a></li>
                                    <li><a href="#">ERP</a></li>
                                    <li><a href="#">Desktop App Development</a></li>
                                    <li><a href="#">Web Development</a></li>
                                    <li><a href="#">Database Administration</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div>
                                <h1>Get In Touch</h1>
                                <ul className="list-unstyled">
                                    <li>+92 332 4778457</li>
                                    {/* <li><a href="#">928029782072</a></li> */}
                                    <li>welcome@technologentsol.com</li>
                                    <li>167-E2 AWT Ph 2 Near A d a P l o t , Raiwind Road,Lahore, Pakistan</li>
                                </ul>
                                <div className="footer-icons d-flex justify-content-start p-3">
                                    <Link to="+92 3324778457" className="mx-2"><img className="whatsapp" src="/images/whatsapp1.png" alt="WhatsApp" /></Link>
                                    <Link to="#" className="mx-2"><img className="whatsapp" src="/images/instagram.png" alt="Instagram" /></Link>
                                    <Link to="#" className="mx-2"><img className="whatsapp" src="/images/linkedin.png" alt="LinkedIn" /></Link>
                                    <Link to="#" className="mx-2"><img className="whatsapp" src="/images/facebook.png" alt="LinkedIn" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
