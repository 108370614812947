import React from "react";
import "../Contact.css";
import Footer from "./Footer";
import { Link, useEffect } from "react-router-dom";

const Contact = () => {
  return (
    <>
        <img className='imageforcontact ' alt='network error' src='/images/groupabouttt.jpg' />
       
      <div className='container mt-5 formcard'>
        <div className='row'>
          <div className='col-md-6'>
            <p className='lines'>
              <span className=' '></span>GET IN TOUCH
            </p>
            <h2 className='mt-5 text-capitalize '>Write a Message To Our Experts</h2>
            <div className='d-flex mt-5 container'>
              <img className='callus img-fluid' src='/images/callus.png' alt="Call Us" />
              <div>
                <h4 className='nomber px-3'>+92 332 4778457</h4>
                <p className='lead ourphone px-3'>Our Phone</p>
              </div>
            </div>

            <div className='d-flex mt-3 container'>
              <img className='callus' src='/images/mailus.png' alt="Mail Us" />
              <div>
                <h4 className='nomber px-3'>welcome@technologentsol.com</h4>
                <p className='lead ourphone px-3'>Our Email</p>
              </div>
            </div>

            <div className='d-flex mt-3 container'>
              <img className='callus' src='/images/location.png' alt="Location" />
              <div>
                <h4 className='nomber px-3'>167-E2 AWT Ph 2 Near A d a P l o t , Raiwind Road,Lahore, Pakistan</h4>
                <p className='lead ourphone px-3'>Our Location</p>
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <h2 className='lines '>Send Us a Message</h2>
            <form>
              <input type='text' name='user_name' className='inputfields mt-5 py-3 lead' placeholder='Full Name' />
              <input name='user_email' type='text' className='inputfields mt-3 py-3 lead' placeholder='Email Address' />
              <input name='message' className='inputfields mt-3 inputpadding lead' placeholder='Write Your Message' />
              <button type='submit' value='Send' className='quote px-4 py-2 mt-4 btn-primary'>
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>

     

      <div className='container-fluid consultation mt-5 py-5'>
        <div className='row'>
          <div className='col-md-8'>
            <h1 className='ready'>Get Free Consultation Now! We Are Ready To Help Your Business</h1>
          </div>
          <div className='col-md-4 gone'>
            <button className='quote px-4 py-2 mt-4 '>Get Free Consulting</button>
          </div>
        </div>
      </div>
      <div className="map mt-5">
            <h2 className="text-center">Find Us Here</h2>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.7433718539356!2d-122.42144728468118!3d37.77902697975815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085807f229d7b89%3A0x3bce5d005e227b0!2sSan%20Francisco%2C%20CA!5e0!3m2!1sen!2sus!4v1637278260387!5m2!1sen!2sus" 
              allowFullScreen="" 
              loading="lazy"
              className="w-100" 
              height="450"
             
              title="Google Maps"
            ></iframe>
          </div>
      <Footer />
    </>
  );
};

export default Contact;
