import React from "react";
import "./Team.css";
import Footer from "./Footer";

const Team = () => {
    return(
        <>
  <h2 className="text-center meetour_team">Meet Our Team</h2>

<div className="container tcontainer">
     <h1 className="text-center"> CEO</h1>
    <div className="main_div">
        <div> 
            <img src="/images/ceo.png" className="ceo_image"/>
        </div>
    </div>   
    <h1 className="text-center">Salman Saboor</h1>
</div>

<div className="container text-center">
      <h1 className="meetour_team">Team Members</h1>
      <div className="row justify-content-center">
        <div className="col-md-4 mb-4 d-flex flex-column align-items-center">
          <div className="team_member">
            <img src="/images/male.png" className="img-fluid team-pics team_image mb-3" alt="CEO" />
            <h2>Shahroz Ubaid</h2>
            {/* <p>CEO</p> */}
          </div>
        </div>
        <div className="col-md-4 mb-4 d-flex flex-column align-items-center">
          <div className="team_member">
            <img src="/images/tayyab.png" className="img-fluid team-pics team_image mb-3" alt="COO" />
            <h2>Muhammad Tayyab</h2>
            {/* <p>COO</p> */}
          </div>
        </div>
        <div className="col-md-4 mb-4 d-flex flex-column align-items-center">
          <div className="team_member">
            <img src="/images/female.png" className="img-fluid team-pics team_image mb-3" alt="CTO" />
            <h2>Sherbano Zaheer</h2>
            {/* <p>CTO</p> */}
          </div>
        </div>
        {/* <div className="container text-center"> */}
      {/* <h2>Meet Our Team</h2>
      <h1>Our Leadership Team</h1> */}
      <div className="row justify-content-center">
        <div className="col-md-6 mb-4 d-flex flex-column align-items-center">
          <div className="team_member">
            <img src="/images/mudasirtwo.png" className="img-fld team-pics team_image mb-3" alt="CEO" />
            <h2>Mudasir Murtaza</h2>
            {/* <p>CEO</p> */}
          </div>
        </div>
        <div className="col-md-6 mb-4 d-flex flex-column align-items-center">
          <div className="team_member">
            <img src="/images/minhaal.png" className="img-fluid team-pics team_image mb-3" alt="COO" />
            <h2>Minhaal</h2>
            {/* <p>COO</p> */}
          </div>
        </div>
        {/* <div className="col-md-4 mb-4 d-flex flex-column align-items-center">
          <div className="team_member">
            <img src="/images/ceo.png" className="img-fluid team-pics team_image mb-3" alt="CTO" />
            <h2>John Smith</h2>
            <p>CTO</p>
          </div>
        </div> */}
      </div>
    </div>
      </div>





{/* <section id="team">
                <div className="container">
                    <div className="team-members row">
                        <div className="member col-md-3">
                            <img src="ceo.jpg" alt="CEO" className="img-fluid" />
                            <h4>CEO</h4>
                                        <p>John Doe</p>
                        </div>
                        <div className="member col-md-3">
                            <img src="team_member1.jpg" alt="Team Member 1" className="img-fluid" />
                            <h4>Team Member</h4>
                            <p>Jane Smith</p>
                        </div>
                        <div className="member col-md-3">
                            <img src="team_member2.jpg" alt="Team Member 2" className="img-fluid" />
                            <h4>Team Member</h4>
                            <p>David Johnson</p>
                        </div>
                        <div className="member col-md-3">
                            <img src="team_member3.jpg" alt="Team Member 3" className="img-fluid" />
                            <h4>Team Member</h4>
                            <p>Emily Brown</p>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer/>
        </>
    );
};

export default Team;
