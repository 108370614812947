import React from 'react';
import '../App.css';
// import { Link } from 'react-router-dom';
import Typewriter from 'react-typewriter-effect';
import { Link, useEffect } from 'react-router-dom';

function Landingpage() {



 
  return (
    <>




<div className='parent_landing' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <div className='image_container' style={{ width: '100%', textAlign: 'center' }}>
    <img className="imagehome" src="/images/check.jpg" alt="image" style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
  </div>
  
  <div className='child_landing container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '800px', marginTop: '-50px' }}>
    <div className='row' style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div className='main_div' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
    <h1 className='itsolutions'>
      <div className='header-text'>IT</div>
      <span className='typewriter-textt'>
        <Typewriter
          startDelay={100}
          cursorColor="white"
          multiText={[
            'Solutions.',
            'Business.',
          ]}
          multiTextDelay={1000}
          typeSpeed={90}
          multiTextLoop
        />
      </span>
    </h1>
    <h1 style={{ marginBottom: '20px', color: 'white' }}>We are Technologent Solutions</h1>
   <Link to="/contact" > <button style={{ padding: '15px 20px',  backgroundColor: '#007BFF', color: '#fff', border: 'none', borderRadius: '10px', cursor: 'pointer' }}>
      Contact Us
    </button> </Link>
  </div>
    </div>
  </div>
</div>

      {/* Uncomment and update this section as needed */}
      
      {/* <div className="row justify-content-center align-items-center">
        <div className="col-md-8 text-center text-white landtext">
          <h1 className="parot">Best Solutions<span className="wantblue"></span></h1>
          <h1 className="text-capitalize landcont">
            EMPOWERING INNOVATION THROUGH CUTTING-EDGE SOFTWARE SOLUTIONS
          </h1>
          <p className="para">
            We are a dynamic team of passionate individuals dedicated to crafting state-of-the-art
            software solutions that propel businesses to new heights. With a commitment to pushing
            technological boundaries, we specialize in developing robust applications, scalable systems,
            and intuitive interfaces tailored to meet the unique needs of our clients. Let 
            <span className="wantblues">Technologent Solutions</span> be your trusted partner in navigating the
            digital landscape and unlocking the full potential of your business. Explore our portfolio and
            discover how we can elevate your enterprise through tailor-made software solutions.
          </p>
          <div className="centermein">
            <Link to="/Contact" className="btn contacting">Contact Us</Link>
          </div>
        </div>
      </div> */}





<div className='expertiseonhome text-center '>
  Our Expertise
</div>

<div className='container mt-5'>
  <div className='row align-items-center'>
    <div className='col-md-6 mb-4 mb-md-0'>
      <img className='againexpert img-fluid' src='/images/againexpert.png' alt='Expertise' />
    </div>
    <div className='col-md-6 expertise_text'>
      Our company boasts extensive expertise in ERP, technology, outsourcing, and digital marketing.We excel in optimizing business operations, leveraging cutting-edge technology, and crafting compelling digital strategies. Whether it's streamlining processes through ERP systems, harnessing the power of outsourcing for efficiency, or driving growth through targeted digital marketing campaigns, our team stands ready to deliver exceptional results tailored to your unique needs.
    </div>
  </div>
</div>







     
<div className='container-fluid '>
<h1 className="text-center  mt-5 portfolioheading">Services</h1>
</div>
<section id="Services" className="py-5 servicescard">
  <div className="container">
    <div className="row">
      <div className="col-md-4 mb-4 d-flex ">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-laptop-code" style={{ fontSize: '70px' }}></i> */}
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Oracle Apex</h2>
            <p className="card-text">
              At Technologent Solutions, we excel in Oracle APEX development, offering robust and scalable web applications. Our expertise ensures efficient database management and seamless integration with existing systems. We create user-friendly interfaces and dynamic reporting tools to enhance your business operations. Trust us to deliver tailor-made solutions that meet your specific needs.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-palette" style={{ fontSize: '70px' }}></i> */}
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">ERP</h2>
            <p className="card-text">
              Technologent Solutions provides comprehensive ERP solutions that streamline your business processes and improve efficiency. Our ERP systems integrate various functions like finance, HR, and supply chain into a cohesive system. We customize ERP platforms to suit your unique requirements, ensuring a smooth transition and improved operational control. Enhance productivity and decision-making with our ERP services.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-cube" style={{ fontSize: '43px' }}></i> */}
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Desktop App Development</h2>
            <p className="card-text">
              Our desktop app development services are designed to create powerful and intuitive applications for your business. We specialize in building cross-platform desktop apps that offer rich functionality and seamless performance. Whether you need a standalone application or a complex system, we deliver solutions that enhance productivity and user experience. Let us bring your desktop application vision to life with advanced features and usability.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-layer-group" style={{ fontSize: '43px' }}></i> */}
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Web Development</h2>
            <p className="card-text">
              We offer top-notch web development services to help you establish a strong online presence. Our team creates responsive, dynamic, and visually appealing websites tailored to your business goals. We use the latest technologies and best practices to ensure your website is fast, secure, and user-friendly. Boost your online visibility and engagement with our expert web development solutions that drive results.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-pen-to-square" style={{ fontSize: '45px' }}></i> */}
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Database Administration</h2>
            <p className="card-text">
              Our database administration services ensure your data is managed securely and efficiently. We offer comprehensive support, including installation, configuration, performance tuning, and backup solutions. Our experienced DBAs work with various database systems to keep your data accessible and safe. Maintain the integrity and availability of your critical business data with our expert database management.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-window-maximize" style={{ fontSize: '55px' }}></i> */}
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Digital Marketing</h2>
            <p className="card-text">
              We offer effective digital marketing services to help you reach your target audience and grow your brand. Our services include SEO, PPC, social media marketing, and content marketing. We create tailored strategies aligned with your business objectives, ensuring maximum online visibility and engagement. Achieve your marketing goals and boost your digital presence with our comprehensive digital marketing solutions.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-video" style={{ fontSize: '70px' }}></i> */}
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Cloud Computing</h2>
            <p className="card-text">
              Our cloud computing services provide scalable and flexible solutions for your business needs. We offer cloud migration, infrastructure management, and cloud-based application development. Leverage the power of the cloud to reduce costs, increase efficiency, and improve accessibility. Experience the benefits of customized cloud solutions that drive your business forward.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-window-restore" style={{ fontSize: '50px' }}></i> */}
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Mobile App Development</h2>
            <p className="card-text">
              We specialize in mobile app development, creating innovative and user-friendly applications for various platforms. Our team designs and develops custom mobile apps that meet your business requirements and enhance customer engagement. We ensure seamless functionality, intuitive design, and optimal performance. Transform your ideas into powerful mobile solutions with our expert development services.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


 {/* <div class="container mt-5">
                <div class="row">
                    <div className="col-md-6">
                        <img src="/images/IMage.png" alt="Image" class="img-fluid"/>
                    </div>
                    <div class="col-md-6 mt-5">
                        <h1 class="text-primary">What We Promise For Successful Company.</h1>
                        <p>Our mission is to help enterprises accelerate adoption of new technologies, untangle complex issues that always emerge during digital evolution, and orchestrate ongoing innovation.</p>
                        <ul>
                            <li>Web Development</li>
                            <li>Mobile Apps Development</li>
                            <li>Business Applications</li>
                            <li>Digital Marketing / SEO</li>
                            <li>Social Media Advertisement</li>
                            <li>IT Consultancy</li>
                        </ul>
                    </div>
                </div>
 </div>

 <div className="container startbusiness">
                <div className="row">
                    <div className="col-md-6">
                        <h1 className="text-primary">What can take your 
                            <br/>Business to the next level</h1>
                        <p>Ready to transform your online presence ? Here are the key goals of our social media marketing services.</p>
                        <ul>
                            <li>Effective Digital Marketing</li>
                            <li>Web and Mobile Presence</li>
                            <li>Product Innovation</li>
                            <li>Data Analysis</li>
                            <li>Enhance Public Relations</li>
                            <li>Driving Sales and Leads</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <img src="/images/IMage 2.png" alt="Image" class="imge-fluid startupgoals"/>
                    </div>
                </div>
 </div>

<div className='container'>
  <h1 className='text-center portfolioheading'>Technologies</h1>
  <p className='text-center fs-5'>Dynamically pursue convergence rather than 24/7 process improvements develop end-to-end customer service action items.</p>
</div>
<div className='container'>
  <img className='skilss' src='/images/Image 1.JPG' alt='imagenotloading'/>
</div>


<div className='container mt-5'>
<h1 className='text-center portfolioheading'>Our portfolio</h1>
</div>



<div className='container'>
      <div className="row">
        <div className="col-md-4 mb-4">
          <div className="card" style={{ width: '100%' }}>
            <img src="/Images/MobileApp1.jpg" className="card-img-top" alt="Web" />
            <div className="card-body">
              <h5 className="card-title">Modern Web Design for Local Agency & Company</h5>
              <a href="#" className="btn btn-primary">MOBILE APPS</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card" style={{ width: '100%' }}>
            <img src="/Images/Mobile App 2.jpg" className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">App Design for a Local Agency and Company</h5>
              <a href="#" className="btn btn-primary">MOBILE APPS</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card" style={{ width: '100%' }}>
            <img src="/Images/Web Local 1.jpg" className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Branch for American IT Business Agency</h5>
              <a href="#" className="btn btn-primary">WEB APPS</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card" style={{ width: '100%' }}>
            <img src="/Images/DM 1.jpg" className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">World Best Available Scope for your Marketing</h5>
              <a href="#" className="btn btn-primary">DIGITAL MARKETING</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card" style={{ width: '100%' }}>
            <img src="/Images/Web Local 3.jpg" className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">What is the exact solution for your IT Business</h5>
              <a href="#" className="btn btn-primary">WEB APPS</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card" style={{ width: '100%' }}>
            <img src="/Images/Web Local 2.jpg" className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">What the new is Upcoming solution for your upcoming problem</h5>
              <a href="#" className="btn btn-primary">WEB APPS</a>
            </div>
          </div>
        </div>
      </div>
    </div> */}



</>  );}

export default Landingpage;
