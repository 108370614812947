import React from "react";
import "../App.css"
import { useEffect,Link } from "react-router-dom";
import Footer from "./Footer";
const About = () =>{

    return(
        <>
        <div className="container-fluid about_full">

       
        <div className="container-fluid cardonet">
        <div className="container ">
        <div className="row">
         <div className="col-md-6 mb-3">
                <img className="img-fluid mt-5 about_firstimage"  src="/images/abouthome.png" alt="img"  />
         </div>
        <div className="col-md-6 ">
        <p className="statusabout"><span className="lines"></span>About Our Company</p>
        <p className="about_para">
        Established inYear 2019 and registered with Securities and ExchangeCommission of Pakistan<br/>
        Our intent is to be premier provider of Next-Gen Technological solutions,solutions which dramatically
improve business performance and create value for client.<br/>
Technologent Solutions was formed with the mission of providing companies with high level of expertise in
business management system design, development, implementation, documentation and maintenance. Our
primary goal is to ensure the customer satisfaction. This is achieved through the building of strong relationship
and long term commitment to the client. We help to ensure your company’s success by thoroughly
understanding your business needs and developing the strategic solutions.</p>
    <Link to="/team">   <button className="quote px-4 py-2 mt-4">Meet Our Team</button></Link> 
        </div>
        </div>


        </div>
       
        </div>


        <div className="container">
  <div className="row align-items-center">
    <div className="col-md-5 text-center text-md-left mt-4">
      <img src="/images/ceo.png" className="img-fluid ceo_edit" alt="CEO" />
      <h1 className="mt-3 salmansaboor">Salman Saboor</h1>
    </div>
    <div className="col-md-7">
      <h1 className="text-center messageforceo">
        Message from our CEO
      </h1>
      <p className="mt-4 paragraphofceo">
        Our CEO, Mr. Salman, emphasizes the importance of innovation and customer satisfaction in our company's mission. He states, "At Technologent Solutions, we are dedicated to pushing the boundaries of what is possible. Our commitment to excellence and our unwavering focus on meeting the needs of our customers drive everything we do. We believe that by fostering a culture of creativity and collaboration, we can deliver solutions that not only meet but exceed our clients' expectations. Together, we will continue to lead the way in our industry and set new standards of success."
      </p>
    </div>
  </div>
</div>


<div className="container text-center expertise_heading">
      Why Choose Us
</div>

<div className="container">
<div className="row">

<div className="col-md-4 mb-4 d-flex ">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-laptop-code" style={{ fontSize: '70px' }}></i> */}
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Expertise</h2>
            <p className="card-text">
            With a proven track record in ERP, technology, outsourcing, and digital marketing, we bring a wealth of knowledge and experience to every project, ensuring high-quality results and client satisfaction.            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-palette" style={{ fontSize: '70px' }}></i> */}
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Customized Solutions</h2>
            <p className="card-text">
            We understand that every business is unique, which is why we tailor our services to meet the specific needs and goals of each client. Our personalized approach ensures that you receive solutions that are perfectly aligned with your objectives.            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          {/* <i className="fa-solid fa-cube" style={{ fontSize: '43px' }}></i> */}
          <div className="card-body dt-flex flex-column justify-content-between">
            <h2 className="card-title">Innovation</h2>
            <p className="card-text">
            In a rapidly evolving business landscape, staying ahead of the curve is essential. We pride ourselves on our innovative thinking and commitment to leveraging the latest technologies and strategies to drive success for our clients.            </p>
          </div>
        </div>
      </div>

</div>

</div>




{/* <div className="container mt-5">
    <div className="row gx-5 whychooseus">
        <div className="col-md-4 mb-3 chosing_cont "><h3 className="ermhead">Expertise</h3>
                    <p className="erp mt-3 lead">With a proven track record in ERP, technology, outsourcing, and digital marketing, we bring a wealth of knowledge and experience to every project, ensuring high-quality results and client satisfaction.</p></div>
        <div className="col-md-4 mb-3 chosing_cont"><h3 className="ermhead">Customized Solutions</h3>
                    <p className="erp mt-3 lead">We understand that every business is unique, which is why we tailor our services to meet the specific needs and goals of each client. Our personalized approach ensures that you receive solutions that are perfectly aligned with your objectives.</p></div>
        <div className="col-md-4 mb-3 chosing_cont"><h3 className="ermhead">Innovation</h3>
                    <p className="erp mt-3 lead">In a rapidly evolving business landscape, staying ahead of the curve is essential. We pride ourselves on our innovative thinking and commitment to leveraging the latest technologies and strategies to drive success for our clients.</p></div>
    </div>
</div> */}



<div className="container-fluid mainstatus">
       <div className="container-fluid mainstatus">
       <div className="row">
       <div className="col-md-6 visioncontainer">
 <img className="img-fluid mt-3 px-4 py-4 missionpass" src="/images/businessmission.png"/>
       </div>
       <div className="col-md-6 mission visioncontainer">
         <div className="mt-3">
            <h1>OUR VISION</h1>
            <p classname="missions_container">Our Mission is “Offering manag-ement and financial services to corporations, guiding policy
            formulation to attain strategic financial and sustainable goals, all within the constraints of
             budgetary limitations. Our commitment extends beyond numbers preparation to strategic
             development that brings those numbers to fruition.”</p>
         </div>
         <div className="mt-3">
            <h1>Our Mission</h1>
            <p classname="missions_container">Our Mission is “Offering manag-ement and financial services to corporations, guiding policy
            formulation to attain strategic financial and sustainable goals, all within the constraints of
             budgetary limitations. Our commitment extends beyond numbers preparation to strategic
             development that brings those numbers to fruition.”</p>
         </div>
         <div className="mt-3">
            <h1>OUR STRATEGY</h1>
            <p classname="missions_container">Our Mission is “Offering manag-ement and financial services to corporations, guiding policy
            formulation to attain strategic financial and sustainable goals, all within the constraints of
             budgetary limitations. Our commitment extends beyond numbers preparation to strategic
             development that brings those numbers to fruition.”</p>
         </div>
       </div>

       </div>

       </div>

       </div>
       </div>
<Footer/>
        </>
    )
}
export default About;