import React from "react";
import "../App.css";
import { Link, } from "react-router-dom";
import Footer from "./Footer";

const Service = () =>{



    return(
        <>

<section id="Services" className="py-5 servicescard">
  <div className="container">
    <div className="row">
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          <i className="fa-solid fa-laptop-code" style={{ fontSize: '70px' }}></i>
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Oracle Apex</h2>
            <p className="card-text">
              At Technologent Solutions, we excel in Oracle APEX development, offering robust and scalable web applications. Our expertise ensures efficient database management and seamless integration with existing systems. We create user-friendly interfaces and dynamic reporting tools to enhance your business operations. Trust us to deliver tailor-made solutions that meet your specific needs.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          <i className="fa-solid fa-palette" style={{ fontSize: '70px' }}></i>
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">ERP</h2>
            <p className="card-text">
              Technologent Solutions provides comprehensive ERP solutions that streamline your business processes and improve efficiency. Our ERP systems integrate various functions like finance, HR, and supply chain into a cohesive system. We customize ERP platforms to suit your unique requirements, ensuring a smooth transition and improved operational control. Enhance productivity and decision-making with our ERP services.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          <i className="fa-solid fa-cube" style={{ fontSize: '43px' }}></i>
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Desktop App Development</h2>
            <p className="card-text">
              Our desktop app development services are designed to create powerful and intuitive applications for your business. We specialize in building cross-platform desktop apps that offer rich functionality and seamless performance. Whether you need a standalone application or a complex system, we deliver solutions that enhance productivity and user experience. Let us bring your desktop application vision to life with advanced features and usability.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          <i className="fa-solid fa-layer-group" style={{ fontSize: '43px' }}></i>
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Web Development</h2>
            <p className="card-text">
              We offer top-notch web development services to help you establish a strong online presence. Our team creates responsive, dynamic, and visually appealing websites tailored to your business goals. We use the latest technologies and best practices to ensure your website is fast, secure, and user-friendly. Boost your online visibility and engagement with our expert web development solutions that drive results.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          <i className="fa-solid fa-pen-to-square" style={{ fontSize: '45px' }}></i>
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Database Administration</h2>
            <p className="card-text">
              Our database administration services ensure your data is managed securely and efficiently. We offer comprehensive support, including installation, configuration, performance tuning, and backup solutions. Our experienced DBAs work with various database systems to keep your data accessible and safe. Maintain the integrity and availability of your critical business data with our expert database management.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          <i className="fa-solid fa-window-maximize" style={{ fontSize: '55px' }}></i>
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Digital Marketing</h2>
            <p className="card-text">
              We offer effective digital marketing services to help you reach your target audience and grow your brand. Our services include SEO, PPC, social media marketing, and content marketing. We create tailored strategies aligned with your business objectives, ensuring maximum online visibility and engagement. Achieve your marketing goals and boost your digital presence with our comprehensive digital marketing solutions.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          <i className="fa-solid fa-video" style={{ fontSize: '70px' }}></i>
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Cloud Computing</h2>
            <p className="card-text">
              Our cloud computing services provide scalable and flexible solutions for your business needs. We offer cloud migration, infrastructure management, and cloud-based application development. Leverage the power of the cloud to reduce costs, increase efficiency, and improve accessibility. Experience the benefits of customized cloud solutions that drive your business forward.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 d-flex">
        <div className="card card-custom text-center flex-fill">
          <i className="fa-solid fa-window-restore" style={{ fontSize: '50px' }}></i>
          <div className="card-body d-flex flex-column justify-content-between">
            <h2 className="card-title">Mobile App Development</h2>
            <p className="card-text">
              We specialize in mobile app development, creating innovative and user-friendly applications for various platforms. Our team designs and develops custom mobile apps that meet your business requirements and enhance customer engagement. We ensure seamless functionality, intuitive design, and optimal performance. Transform your ideas into powerful mobile solutions with our expert development services.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>













{/* <div className="container mt-3">
    <div className="row">
        <div className="col-md-3 col-sm-6 col-12 mb-3">
            <div className="image-container">
                <img className="firstimage img-fluid" src="/images/technology.png" alt="expert"/>
            </div>
            <div className="mt-5">
                <h2 className="ermhead">Technology</h2>
                <p className="erp mt-3 lead">Harnessing cutting-edge technology, we offer comprehensive services from ERM system development to dynamic mobile app creation and bespoke E-commerce solutions. Our expertise extends to seamless system integration, customized solutions for enhanced efficiency, and robust web application development, empowering businesses in the digital realm.</p>
            </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12 mb-3">
            <div className="image-container">
                <img className="firstimage img-fluid" src="/images/consulting.png" alt="expert"/>
            </div>
            <div className="mt-5">
                <h2 className="ermhead">Consulting</h2>
                <p className="erp mt-3 lead">Our consulting services blend IT expertise, digital marketing strategies, meticulous documentation, and business management consultancy to drive efficiency and sustainable growth. With tailored solutions, we enhance clients' competitive edge by optimizing IT infrastructure, crafting digital campaigns, ensuring compliance, and refining business strategies.</p>
            </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12 mb-3">
            <div className="image-container">
                <img className="firstimage img-fluid" src="/images/onlinetrainingicon.png" alt="expert"/>
            </div>
            <div className="mt-5">
                <h2 className="ermhead">Trainings</h2>
                <p className="erp mt-3 lead">Our training services offer a diverse range of skills crucial for success in today's competitive landscape, including IT expertise, executive leadership, HR management, sales and marketing techniques, and mobile app development. With tailored programs catering to various organizational needs, we empower teams to optimize strategies and drive business growth, cultivating a highly skilled workforce capable of achieving strategic objectives with confidence.</p>
            </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12 mb-3">
            <div className="image-container">
                <img className="firstimage img-fluid" src="/images/outsourcing.png" alt="expert"/>
            </div>
            <div className="mt-5">
                <h3 className="ermhead">Outsourcing</h3>
                <p className="erp mt-3 lead">Our Business Process Outsourcing (BPO) services streamline operations, from financial accounting to HR recruitment, freeing your team to focus on core objectives. With expertise in digital marketing and sales surveys, we drive growth while optimizing your business operations.</p>
            </div>
        </div>
    </div>
</div>

<div >
    <Link to="/service" className="linkservicebutton"><button className="viewservice">View All Services</button></Link>
</div>


 */}


{/* <div className="container text-center expertise_heading">

        
        Our Expertise

        </div>
        <div className="container lead expertise_Paragraph">
        Our company boasts extensive expertise in ERP, technology, outsourcing, and digital marketing. With a proven track record of successful implementations and innovative solutions, we excel in optimizing business operations, leveraging cutting-edge technology, and crafting compelling digital strategies. Whether it's streamlining processes through ERP systems, harnessing the power of outsourcing for efficiency, or driving growth through targeted digital marketing campaigns, our team stands ready to deliver exceptional results tailored to your unique needs.

        </div>

        <div className="container mt-3">
        <div className="row">
            <div className="col-md-3 col-sm-6 col-12 mb-3">
                <div className="image-container">
                    <img className="firstimage img-fluid" src="/images/first.png" alt="expert"/>
                </div>
                <div className="mt-5">
                    <h2 className="ermhead">ERM System</h2>
                    <p className="erp mt-3 lead">Specializing in ERP systems, we optimize business operations and streamline processes to drive efficiency and maximize performance</p>
                </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12 mb-3">
                <div className="image-container">
                    <img className="firstimage img-fluid" src="/images/second.png" alt="expert"/>
                </div>
                <div className="mt-5">
                    <h2 className="ermhead">Technology</h2>
                    <p className="erp mt-3 lead">Leaders in technology innovation and implementation, driving digital transformation with expertise in cutting-edge solutions</p>
                </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12 mb-3">
                <div className="image-container">
                    <img className="firstimage img-fluid" src="/images/third.png" alt="expert"/>
                </div>
                <div className="mt-5">
                    <h2 className="ermhead">Out Sourcing</h2>
                    <p className="erp mt-3 lead">Our outsourcing expertise ensures streamlined operations and enhanced efficiency through strategic partnerships and optimized resource allocation</p>
                </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12 mb-3">
                <div className="image-container">
                    <img className="firstimage img-fluid" src="/images/fourth.png" alt="expert"/>
                </div>
                <div className="mt-5">
                    <h3 className="ermhead">Digital Marketing</h3>
                    <p className="erp mt-3 lead">Our digital marketing expertise encompasses strategic planning, data-driven campaign execution, and ROI-focused optimization to maximize online visibility and drive customer engagement.</p>
                </div>
            </div>
        </div>
    </div>

    <div >
        <Link to="/service" className="linkservicebutton"><button className="viewservice">View All Services</button></Link>
    </div> */}


<Footer/>

        </>
    )
}

export default Service;